
<template>
    <div>

        <div class="container">
            <div class="col-lg-12">
                <h3 class="mb-0 text-uppercase">Life License Details</h3>
                <hr class="my-4" />
            </div>
            
            <div class="row" v-if="record">                
                <div class="col-lg-12"><br><br></div>
                <div class="col-lg-12">
                    <div class="row justify-content-center">
                        <div class="col-lg-3 order-lg-2">
                            <div class="card-profile-image">
                                <a href="javascript:void(0)">
                                    <img 
                                        v-if="record.has_file"
                                        :src="record.url" 
                                        class="rounded-circle image-size"
                                    >
                                    <img 
                                        v-if="! record.has_file"
                                        :src="blankImageUrl" 
                                        class="rounded-circle image-size"
                                    >
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12"><br><br><br><br><br><br></div>
                <div class="col-lg-12">
                    <div class="row justify-content-center">
                        <h3 class="text-danger">Recent Photograph Uploaded</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 text-center" v-if="! record">
                <base-alert type="danger">
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        No Life License Details, Kindly reject application for Doctor to re-apply
                    </span>
                </base-alert>  
            </div>
            <hr class="my-4" />
        </div>

    </div>
</template>
<script>
import { mapGetters } from 'vuex';
    export default {
        name: 'LifeLicense',
        props: ['record'],
        computed: {
            ...mapGetters(['blankImageUrl'])
        }
    };
</script>
<style scoped>
    .marginTop40 {
        margin-top: 40px;
    }
    .image-size {
        height: 180px;
        width: 180px;
    }
</style>
