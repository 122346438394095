
<template>
    <div>
        <!-- <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-success opacity-8"></span>
        </base-header> -->

        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <!-- Card stats -->
            <div class="row" v-if="! isLoadingBlade">
                <div class="col-xl-4 col-lg-6"
                    v-if="dashbardUserInfo && dashbardUserInfo.profile"
                >
                    <stats-card title="Profile Completeness"
                                :type="'gradient-'+dashbardUserInfo.profile.color"
                                :sub-title="(dashbardUserInfo.profile.percent)+'%'"
                                icon="ni ni-circle-08"
                                class="mb-4 mb-xl-0"
                                :class="dashbardUserInfo.profile.color"
                    >
                    </stats-card>
                </div>
                <div
                    class="col-xl-4 col-lg-6"
                    v-if="dashbardUserInfo && dashbardUserInfo.insurance"
                >
                  <router-link
                      to="/insurance-indemnity"
                  >
                      <stats-card title="Insurance Indemnity"
                                  :type="'gradient-'+dashbardUserInfo.insurance.color"
                                  :sub-title="dashbardUserInfo.insurance.status"
                                  icon="ni ni-credit-card"
                                  class="mb-4 mb-xl-0"
                                  :class="dashbardUserInfo.insurance.color"
                      >
                      </stats-card>
                  </router-link>

                </div>
                <div
                    class="col-xl-4 col-lg-6"
                    v-if="dashbardUserInfo && dashbardUserInfo.license"
                >
                  <router-link
                      to="/my-license"
                  >
                    <stats-card title="License Expiry Date"
                                :type="'gradient-'+dashbardUserInfo.license.color"
                                :sub-title="dashbardUserInfo.license.expiry_date | getDateFormat"
                                icon="ni ni-spaceship"
                                class="mb-4 mb-xl-0"
                                :class="dashbardUserInfo.license.color"
                    >
                    </stats-card>
                  </router-link>

                </div>
            </div>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">
                                        {{ application && application.application_type ?
                                            application.application_type.name : 'N/A' }}
                                    </h3>
                                </div>
                                <div class="col-4 text-right">
                                    <a href="#!" @click="$router.go(-1)" class="btn btn-sm btn-danger">
                                      <i class="fa fa-reply"></i> Back
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <div v-if="! isLoadingBlade && ! approvalPage">

                          <!-- Import basic details template here -->
                          <BasicDetails
                            :application="application"
                            :loadApplication="loadApplication"
                            :privileges="privileges"
                            :isProvisionalApp="isProvisionalApp"
                          />

                          <!-- Import Additional Records template here for only Temp Doctor -->
                          <AdditionalRecords
                            v-bind:record="application.user_data"
                            v-if="isTempDoctor"
                          />

                          <!-- Import Change of Name template here for only Change of Name application -->
                          <ChangeOfName
                            v-bind:record="application.has_change_name"
                            v-if="code == 'change-of-name' && application"
                          />

                          <!-- Import AQ template here for only AQ application -->
                          <AddQualification
                            :record="application.has_additional_qualification"
                            :breakWord="breakWord"
                            v-if="code == 'additional-pgq' && application"
                          />

                          <!-- Import AQ template here for only AQ application -->
                          <AddQualification
                            :record="application.has_additional_qualification"
                            :breakWord="breakWord"
                            v-if="code == 'limited-reg' && application"
                          />

                          <!-- Import AQ template here for Full without AQ & Temp application -->
                          <AddQualification
                            :record="application.license_certificate.aq_certificate.aq.has_additional_qualification"
                            :docs="application.license_certificate.aq_certificate.aq.documents"
                            :breakWord="breakWord"
                            v-if="(code == 'full-aq-reg' || code == 'limited-reg' || code == 'limited-reg-long') 
                                && application && application.license_certificate
                                && application.license_certificate.aq_certificate
                                && application.license_certificate.aq_certificate.aq"
                          />

                          <!-- Import Life License template here for only Life License application -->
                          <LifeLicense
                            v-bind:record="application.life_license_file"
                            v-if="code == 'life-license' && application"
                          />

                          <!-- Import LOGs template here for only LOGs application -->
                          <GoodStanding
                            v-bind:record="application.has_good_standing"
                            v-bind:application="application"
                            v-bind:privileges="privileges"
                            v-if="code == 'cert-good-standing' && application"
                          />

                          <!-- Import Revalidation template here for only Revalidation application -->
                          <Revalidation
                            v-bind:record="application.revalidation_file"
                            v-if="code == 'reval-reg' && application"
                          />

                          <!-- Import CTC Full Reg template here for only CTC Full Reg application -->
                          <CtcFullReg
                            v-bind:record="application.ctc_full_file"
                            v-if="code == 'ctc-full-reg' && application"
                          />

                          <!-- Import CPD points template here for only Annual license application -->
                          <CpdPoints
                            v-bind:points="application.user_data.has_cpd_points"
                            v-bind:userId="application.user_data.user.id"
                            v-bind:privileges="privileges"
                            v-bind:loadApplication="loadApplication"
                            v-if="application && application.user_data && application.user_data.user
                                && application.user_data.has_cpd_points && application.application_type
                                && ((application.application_type.code == 'annual-license-old')
                                || (application.application_type.code == 'annual-license'))"
                          />

                          <!-- Import personal details template here -->
                          <PersonalDetails
                            v-bind:user="application.user_data"
                            v-bind:isTempDoctor="isTempDoctor"
                            v-if="application && application.user_data"
                          />

                          <!-- Import payment details template here -->
                          <PaymentDetails
                            :transaction="application.transaction"
                            :nma="application.nma_levy"
                            v-if="application && application.transaction"
                          />

                          <!-- Import late payment details template here -->
                          <LatePaymentDetails
                            v-bind:records="application.late_payment"
                            v-if="application && application.late_payment"
                          />

                          <!-- Import Insurance Records template here -->
                          <Insurance
                            v-bind:record="application.insurance"
                            v-if="application"
                          />

                          <!-- Import Educational Records template here -->
                          <EduRecords
                            :eduRecords="application.user_data.has_educational_record"
                            :breakWord="breakWord"
                            v-if="application && application.user_data
                              && application.user_data.has_educational_record"
                          />

                          <!-- Import Appointment Records template here -->
                          <AppointmentRecords
                            :appointments="application.user_data.has_appointments"
                            :breakWord="breakWord"
                            v-if="application && application.user_data
                              && application.user_data.has_appointments"
                          />

                          <!-- Import Internship Documents template here -->
                          <InternshipDocuments
                            v-bind:userId="application.user_data_id"
                            v-if="application && ! isTempDoctor && ! isProvisional"
                          />

                          <!-- Import Documents template here -->
                          <Documents
                            v-bind:documents="application.documents"
                            v-if="application && application.documents"
                          />

                          <div 
                              v-if="((application.status == 'pending') && (application.payment_status == 'not-paid'))"
                              class="col-sm-12 text-center"
                          >
                              <base-alert type="warning">
                                  <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                  <span class="alert-inner--text">
                                      Note: Doctor has not paid or add payment to this application yet!!!
                                  </span>
                              </base-alert>
                          </div>

                          <div v-if="((application.status == 'pending') && (application.payment_status == 'paid'))">
                            <base-button
                                type="danger" size="lg"
                                @click="showApprovalPage('reject')"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            >
                                <i class="fa fa-ban"></i> Reject
                            </base-button>

                            <base-button
                                type="success" size="lg"
                                class="right"
                                @click="showApprovalPage('approve')"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            >
                                <i class="fa fa-check"></i> Approve
                            </base-button>
                          </div>

                        </div>

                        <div v-if="approvalPage">

                          <!-- Import Approve Application template here -->
                          <ApproveApplication
                            :application="application"
                            v-bind:privileges="privileges"
                            v-bind:loadApplication="loadApplication"
                            v-bind:expiryDate="expiryDate"
                            v-bind:hideApprovePage="hideApprovePage"
                            v-on:hideApprovePage="hideApprovePage"
                            :isProvisionalApp="isProvisionalApp"
                            v-if="pageType == 'approve'"
                          />

                          <!-- Import Reject Application template here -->
                          <RejectApplication
                            v-bind:application="application"
                            v-bind:privileges="privileges"
                            v-bind:loadApplication="loadApplication"
                            v-on:hideApprovePage="hideApprovePage"
                            v-if="pageType == 'reject'"
                          />

                        </div>

                        <div class="col-sm-12 text-center">
                          <base-alert :type="errorType" v-if='showErrorStatus'>
                              <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                              <span class="alert-inner--text">
                                  {{errorMsg}}
                              </span>
                          </base-alert>
                        </div>

                    </card>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import BasicDetails from './BasicDetails';
  import PersonalDetails from './PersonalDetails';
  import PaymentDetails from './PaymentDetails';
  import EduRecords from './EduRecords';
  import AppointmentRecords from './AppointmentRecords';
  import Documents from './Documents';
  import ApproveApplication from './ApproveApplication';
  import RejectApplication from './RejectApplication';
  import CpdPoints from './Others/CpdPoints';
  import InternshipDocuments from './InternshipDocuments';
  import Insurance from './Insurance';
  import ChangeOfName from './Others/ChangeOfName';
  import AddQualification from './Others/AddQualification';
  import GoodStanding from './Others/GoodStanding';
  import LifeLicense from './Others/LifeLicense';
  import Revalidation from './Others/Revalidation';
  import AdditionalRecords from './Others/AdditionalRecords';
  import LatePaymentDetails from './LatePaymentDetails';
  import CtcFullReg from './Others/CtcFullReg';
  import moment from 'moment';

  export default {
    name: 'ApplicationDetail',
    components: {
      BasicDetails,
      PersonalDetails,
      PaymentDetails,
      EduRecords,
      AppointmentRecords,
      Documents,
      ApproveApplication,
      CpdPoints,
      RejectApplication,
      InternshipDocuments,
      Insurance,
      ChangeOfName,
      AddQualification,
      GoodStanding,
      LifeLicense,
      Revalidation,
      AdditionalRecords,
      LatePaymentDetails,
      CtcFullReg
    },
    data() {
      return {
        code: this.$route.params.code,
        id: this.$route.params.id,
        application: {},
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        isLoadingBlade: true,
        approvalPage: false,
        pageType: null
      }
    },
    filters: {
        getDateFormat(value) {
            if(value) {
              return moment(value).format('Do MMM, YYYY');
            } else {
              return 'N/A';
            }
        }
    },
    computed: {
      ...mapGetters(['loadingBladeUrl', 'menus', 'dashbardUserInfo']),
      privileges() {
        let privileges = null;
        if(this.menus && this.menus.length > 0) {
          for(let menu of this.menus) {
              if(menu.has_child && menu.has_child.length > 0) {
                  for(let child of menu.has_child) {
                      if('/'+child.link+'/'+this.id == this.$route.path) {
                          privileges = child.privileges;
                      }
                  }
              }
          }
        }
        return privileges;
      },
      isTempDoctor() {
          let user = this.application.user_data;
          let regType = user && user.has_sub_registration_type && user.has_sub_registration_type.registration_type
              ? user.has_sub_registration_type.registration_type : false;
          return regType ? regType.code == 'temporary-reg' : false;
      },
      isProvisional() {
          let user = this.application.user_data;
          let regType = user && user.has_sub_registration_type && user.has_sub_registration_type.registration_type
              ? user.has_sub_registration_type.registration_type : false;
          return regType ? regType.code == 'provisional-reg' : false;
      },
      expiryDate() {
          let plus = (this.application && this.application.application_type
                      && ((this.application.application_type.code == 'prov-reg' ) ||
                      (this.application.application_type.code == 'limited-reg-long' )) ? 1 : 0);
          let date = moment().year() + plus;
          return date+'-12-31';
      },
      isProvisionalApp() {
          return this.application && this.application.application_type
                  && (this.application.application_type.code == 'prov-reg' ||
                    this.application.application_type.code == 'foreign-prov-reg');
      }
    },
    methods: {
      ...mapActions(['getApplication', 'getDashboardInfo']),
      loadApplication() {// load Application
        this.getApplication({'id' : this.id}).then((res) => {// get records
            this.application = res;
            if(res && res.user_data && res.user_data.user_id) {// get user profile
              this.getDashboardInfo(res.user_data.user_id)
            }
            this.isLoadingBlade = false;
        }).
        catch(() => {
            this.isLoadingBlade = false;
            this.showErrorStatus = true;
            this.errorMsg = "Server Failure!, Kindly reload the page...";
            this.errorType = 'danger';
        });
      },
      showApprovalPage(type) {// show approval/reject page
        this.approvalPage = true;
        this.pageType = type;
      },
      hideApprovePage() {// hide approve page
        this.approvalPage = false;
        this.pageType = null;
      },
      breakWord(word, length = 40) {// break word into two parts
        if(word) {
            let textLength = word.length;
            let exploded = word.split(' ');
            if(word && textLength < length) {
                return word;
            } else if(word) {
                let formedWord = '';
                let half = (exploded.length > 2) ? Math.ceil((exploded.length - 1)/2) : 0;
                for(let phrase of exploded) {
                    formedWord += phrase+' ';
                    if(exploded[half] == phrase) {
                        formedWord += ' <br> ';
                    }
                }
                return formedWord;
            }
        }
        return 'N/A';
      }
    },
    mounted() {
      this.loadApplication();// load Application
    }
  };
</script>
<style scoped>
.right {
  float: right;
}
</style>
