
<template>
    <div>

        <div class="container">
            <div class="row">

                <div class="col-lg-12">
                    <h3 class="mb-0 text-uppercase">Educational Records</h3>
                    <hr class="my-4" />
                </div>

                <div
                    class="table-responsive"
                    v-if="eduRecords.length > 0"
                >

                    <table class="table table-bordered">

                        <thead>
                            <th>School Type</th>
                            <th>School Name</th>
                            <th>Country</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                        </thead>

                        <tbody
                            v-for="record in eduRecords"
                            :key="record.id"
                        >
                            <td>
                                <h4 class="text-capitalize font-weight-light">
                                    {{ replaceString(record.school_type) }}
                                </h4>
                            </td>
                            <td>
                                <h4 class="font-weight-light">
                                    <span v-html="breakWord(record.school_name, 60)"></span>
                                </h4>
                            </td>
                            <td>
                                <h4 class="font-weight-light">
                                    {{ record.has_country && record.has_country.name ? record.has_country.name : 'N/A' }}
                                </h4>
                            </td>
                            <td>
                                <h4 class="font-weight-light">
                                    {{ record.start_date | formatDate }}
                                </h4>
                            </td>
                            <td>
                                <h4 class="font-weight-light">
                                    {{ record.end_date | formatDate }}
                                </h4>
                            </td>
                        </tbody>

                    </table>

                </div>

                <div
                    v-if="eduRecords.length == 0"
                    class="col-sm-12 text-center"
                >
                    <base-alert type="warning">
                        <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                        <span class="alert-inner--text">
                            No educational records added yet!!!
                        </span>
                    </base-alert>
                </div>

                <div class="col-lg-12">
                    <hr class="my-4" />
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    import moment from 'moment';

    export default {
        name: 'EduRecords',
        props: ['eduRecords', 'breakWord'],
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        methods: {
            replaceString(value) {
                return value.replace("-", " ");
            }
        }
    };
</script>
<style scoped>
    .marginTop40 {
        margin-top: 40px;
    }
</style>
