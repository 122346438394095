
<template>
    <div>

        <div class="container">
            <div class="col-lg-12">
                <h3 class="mb-0 text-uppercase">
                    Revalidation of Provisional Registration Uploaded Documents
                </h3>
                <hr class="my-4" />
            </div>

            <div class="row" v-if="record">
                <div class="col-lg-12" v-if="record.documents && record.documents.length > 0">
                    <div class="row">
                        <div 
                            class="col-sm-6 text-center borderLine" 
                            v-for="(document, index) in record.documents"
                            :key="index"
                        >      
                            <br>
                            <h4 class="text-capitalize height-50"
                                v-if="document.hasFile"
                            >
                                {{ document.name }}
                            </h4>
                            <h4 class="text-capitalize height-50"
                                v-if="! document.hasFile"
                            >
                                {{ document.name }}
                            </h4>
                            <br> 
                            <badge
                                v-if="! document.hasFile"
                                type="danger" 
                            >
                                Not Uploaded
                            </badge> 
                            <base-button 
                                tag="a" type="primary" 
                                size="sm" 
                                v-if="document.hasFile"
                                :href="document.url"
                                class="active" role="button" 
                                aria-pressed="true"
                                target="_blank"
                            >
                                <i class="fa fa-eye"></i> View File
                            </base-button>          
                        </div>
                    </div>
                </div>
            </div> 

            <div class="col-lg-12 text-center" v-if="! record">
                <base-alert type="danger">
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        No Revalidation of Provisional Registration Details, 
                        Kindly reject application for Doctor to re-apply
                    </span>
                </base-alert>  
            </div>
            <hr class="my-4" />

        </div>

    </div>
</template>
<script>
    export default {
        name: 'Revalidation',
        props: ['record'],
    };
</script>
<style scoped>
    .marginTop40 {
        margin-top: 40px;
    }
</style>
