<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-success opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">{{ applicationType.name }}</h3>
                                </div>
                                <div class="col-4 text-right">
                                    <!-- <a href="#!" class="btn btn-sm btn-primary">Settings</a> -->
                                </div>
                            </div>
                        </div>

                        <template>
                            <tabs fill class="flex-column flex-md-row">
                                <card shadow>                                    

                                    <tab-pane title="Pending Applications">
                                        <span slot="title">
                                            <i class="ni ni-books"></i>
                                            Pending Applications
                                        </span>
                                        <!-- import Pending Applications template here -->
                                        <PendingApplications
                                            :applicationType="applicationType"
                                            :paymentStatus="'paid'"
                                        />
                                    </tab-pane>

                                    <tab-pane title="Rejected Applications">
                                        <span slot="title">
                                            <i class="ni ni-badge"></i>
                                            Rejected Applications
                                        </span>
                                        <!-- import Rejected Applications template here -->
                                        <RejectedApplications />
                                    </tab-pane>

                                    <tab-pane title="Completed Applications">
                                        <span slot="title">
                                            <i class="ni ni-archive-2"></i>
                                            Completed Applications
                                        </span>
                                        <!-- import Completed Applications template here -->
                                        <CompletedApplications />
                                    </tab-pane>

                                    <tab-pane 
                                        title="InComplete Applications"
                                        v-if="isTempApp"
                                    >
                                        <span slot="title">
                                            <i class="ni ni-books"></i>
                                            Incomplete (Not-Paid) Applications
                                        </span>
                                        <!-- import Pending Applications template here -->
                                        <PendingApplications
                                            :applicationType="applicationType"
                                            :paymentStatus="'not-paid'"
                                        />
                                    </tab-pane>

                                </card>
                            </tabs>

                        </template>

                        <div class="col-sm-12 text-center">
                          <base-alert :type="errorType" v-if='showErrorStatus'>
                              <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                              <span class="alert-inner--text">
                                  {{errorMsg}}
                              </span>
                          </base-alert>
                        </div>

                    </card>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
  import { mapActions } from 'vuex';
  import PendingApplications from './PendingApplications/PendingApplications';
  import RejectedApplications from './RejectedApplications/RejectedApplications';
  import CompletedApplications from './CompletedApplications/CompletedApplications';

  export default {
    name: 'Applications',
    components: {
      PendingApplications,
      RejectedApplications,
      CompletedApplications
    },
    data() {
      return {
        code: this.$route.params.code,
        applicationType: {},
        showErrorStatus: false,
        errorType: null,
        errorMsg: null
      }
    },
    computed: {
        isTempApp() {// check for temporary reg apps only
            let validCodes = ['limited-reg', 'limited-reg-long', 'limited-reg-renewal', 'limited-reg-long-renewal'];
            let code  = this.applicationType && this.applicationType.code ? this.applicationType.code : false;
            return code && validCodes.includes(code);
        }
    },
    methods: {
      ...mapActions(['getApplicationType']),
      loadApplicationType() {
        this.getApplicationType({'code' : this.code}).then((res) => {// get records
            this.applicationType = res;
        }).
        catch(() => {
            this.showErrorStatus = true;
            this.errorMsg = "Server Failure!, Kindly reload the page...";
            this.errorType = 'danger';
        });
      },
    },
    mounted() {
      this.loadApplicationType();// load Application Type
    }
  };
</script>
<style></style>
