
<template>
    <div>

        <div class="container">
            <div class="row">

                <div class="col-lg-12">
                    <h3 class="mb-0 text-uppercase">Uploaded Documents</h3>
                    <hr class="my-4" />
                </div>

                <div class="col-lg-12" v-if="documents && documents.length > 0">
                    <div class="row">
                        <div
                            class="col-sm-4 text-center borderLine"
                            v-for="(document, index) in documents"
                            :key="index"
                        >
                            <br>
                            <h4 class="text-capitalize height-50">
                                {{ document.description ? document.description : 'No Document Uploaded' }}
                            </h4><br>
                            <base-button
                                tag="a" type="primary"
                                size="sm"
                                v-if="document.hasFile"
                                :href="document.url"
                                class="active" role="button"
                                aria-pressed="true"
                                target="_blank"
                            >
                                <i class="fa fa-eye"></i> View File
                            </base-button>

                            <badge size="sm"
                                type="danger"
                                v-if="! document.hasFile"
                            >
                                No Document Uploaded
                            </badge>
                        </div>
                    </div>
                </div>

                <div
                    v-if="documents && documents.length == 0"
                    class="col-sm-12 text-center"
                >
                    <base-alert type="warning">
                        <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                        <span class="alert-inner--text">
                            No documents added yet!!!
                        </span>
                    </base-alert>
                </div>

                <div class="col-lg-12">
                    <hr class="my-4" />
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    import moment from 'moment';

    export default {
        name: 'Documents',
        props: ['documents'],
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        methods: {
            getStatus(status) {
                return (status == 'yes') ? 'Current' : 'Past';
            }
        }
    };
</script>
<style scoped>
    .marginTop40 {
        margin-top: 40px;
    }
</style>
