
<template>
    <div>

        <div class="container">
            <div class="row">

                <div class="col-lg-12">
                    <h3 class="mb-0 text-uppercase">Insurance Record</h3>
                    <hr class="my-4" />
                </div>

                <div 
                    class="table-responsive"
                    v-if="record"
                >                    
                    <table class="table table-bordered">

                        <thead>
                            <th>Beneficiary Name</th>
                            <th>Policy Number</th>
                            <th>Trans Number</th>
                            <th>Expiry Date</th>
                        </thead>

                        <tbody>
                            <td>
                                <h4 class="text-capitalize font-weight-light">
                                    {{ record.beneficiary_name }}
                                </h4>
                            </td>
                            <td>
                                <h4 class="font-weight-light">
                                    {{ record.policy_no }}
                                </h4>
                            </td>
                            <td>
                                <h4 class="font-weight-light">
                                    {{ record.trans_no }}
                                </h4>
                            </td>
                            <td>
                                <h4 class="font-weight-light">
                                    {{ record.expiry_date | formatDate }}
                                </h4>
                            </td>
                        </tbody>  

                    </table>

                </div>

                <div 
                    v-if="! record"
                    class="col-sm-12 text-center"
                >
                    <base-alert type="warning">
                        <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                        <span class="alert-inner--text">
                            No Valid Insurance Yet !!!
                        </span>
                    </base-alert>
                </div>
                
                <div class="col-lg-12">
                    <hr class="my-4" />
                </div>
            </div> 
        </div>
    </div>
</template>
<script>
    import moment from 'moment';

    export default {
        name: 'Insurance',
        props: ['record'],
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        }
    };
</script>
<style scoped>
    .marginTop40 {
        margin-top: 40px;
    }
</style>
