
<template>
    <div>

        <div class="container">
            <div class="row">

                <div class="col-lg-12">
                    <h3 class="mb-0 text-uppercase">Personal Details</h3>
                    <hr class="my-4" />
                </div>

                <div class="table-responsive">
                    
                    <table class="table table-bordered">
                        <tbody>
                            <td>
                                <h4>Full Name</h4>
                            </td>
                            <td>
                                <h4 class="text-capitalize font-weight-light">
                                    {{ getFullName(user) }}
                                </h4>
                            </td>
                            <td>
                                <h4>Folio Number</h4>
                            </td>
                            <td>
                                <h4 class="text-uppercase font-weight-light">
                                    {{ user.folio_number ? user.folio_number : 'N/A' }}
                                </h4>
                            </td>
                        </tbody> 
                        <tbody>
                            <td>
                                <h4>Email Address</h4>
                            </td>
                            <td>
                                <h4 class="text-lowercase font-weight-light">
                                    {{ user.user ? user.user.email : 'N/A' }}
                                </h4>
                            </td>
                            <td>
                                <h4>Phone Number</h4>
                            </td>
                            <td>
                                <h4><h4 class="text-lowercase font-weight-light">
                                    {{ user.phone }}
                                </h4></h4>
                            </td>
                        </tbody> 
                        <tbody>
                            <td>
                                <h4>Gender</h4>
                            </td>
                            <td>
                                <h4 class="text-capitalize font-weight-light">
                                    {{ user.gender }}
                                </h4>
                            </td>
                            <td>
                                <h4>Date of Birth</h4>
                            </td>
                            <td>
                                <h4><h4 class="text-capitalize font-weight-light">
                                    {{ user.date_of_birth | formatDate }}
                                </h4></h4>
                            </td>
                        </tbody> 

                        <tbody>
                            <td>
                                <h4>Marital Status</h4>
                            </td>
                            <td>
                                <h4 class="text-capitalize font-weight-light">
                                    {{ user.marital_status }}
                                </h4>
                            </td>
                            <td>
                                <h4>Place of Birth</h4>
                            </td>
                            <td>
                                <h4><h4 class="text-capitalize font-weight-light">
                                    {{ user.place_of_birth }}
                                </h4></h4>
                            </td>
                        </tbody> 

                        <tbody>
                            <td>
                                <h4>Previous Name</h4>
                            </td>
                            <td>
                                <h4><h4 class="text-capitalize font-weight-light">
                                    {{ user.previous_names ? user.previous_names : 'N/A' }}
                                </h4></h4>
                            </td>
                            <td>
                                <h4>Nationality</h4>
                            </td>
                            <td>
                                <h4 class="text-capitalize font-weight-light">
                                    {{ getLocationName(user.nationality, 'country') }}
                                </h4>
                            </td>
                        </tbody> 

                        <tbody>
                            <td>
                                <h4>State of Origin</h4>
                            </td>
                            <td>
                                <h4 
                                    v-if="user.nationality"
                                    class="text-capitalize font-weight-light"
                                >
                                    {{ user.nationality == 164 ? 
                                        getLocationName(user.state_origin, 'state') : 
                                        user.foreign_state }}
                                </h4>
                            </td>
                            <td 
                                v-if="user.nationality && (user.nationality == 164)
                                    && user.lga_origin"
                            >
                                <h4>LGA of Origin</h4>
                            </td>
                            <td>
                                <h4 class="text-capitalize font-weight-light">
                                    {{ getLocationName(user.lga_origin, 'lga') }}
                                </h4>
                            </td>
                        </tbody> 

                        <tbody>
                            <td>
                                <h4>Home Town</h4>
                            </td>
                            <td colspan="3">
                                <h4><h4 class="text-capitalize font-weight-light">
                                    {{ user.hometown }}
                                </h4></h4>
                            </td>
                        </tbody> 

                        <tbody>
                            <td>
                                <h4>Permanent Address</h4>
                            </td>
                            <td colspan="3">
                                <h4><h4 class="text-capitalize font-weight-light">
                                    {{ user.permanent_address }}
                                </h4></h4>
                            </td>
                        </tbody>

                        <tbody v-if="isTempDoctor">
                            <td>
                                <h4>Residential Address <br>(In Nigeria)</h4>
                            </td>
                            <td colspan="3">
                                <h4><h4 class="text-capitalize font-weight-light">
                                    {{ user.residential_address }}
                                </h4></h4>
                            </td>
                        </tbody>

                        <tbody>
                            <td>
                                <h4>Postal Address</h4>
                            </td>
                            <td colspan="3">
                                <h4><h4 class="text-capitalize font-weight-light">
                                    {{ user.postal_address ? user.postal_address : 'N/A' }}
                                </h4></h4>
                            </td>
                        </tbody>

                    </table>

                </div>

                <div class="col-lg-12">
                    <hr class="my-4" />
                </div>
            </div>  
        </div>

    </div>
</template>
<script>
    import moment from 'moment';
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'PersonalDetails',
        props: ['user', 'isTempDoctor'],
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        computed: {
            ...mapGetters(['locations'])
        },
        methods: {
            ...mapActions(['getLocations']),
            getFullName(profile) {
                let name = profile.first_name+' ';
                name += profile.other_name ? profile.other_name.replace(',', '')+' ' : '';
                name += profile.last_name;
                return name;
            },
            getLocationName(value, type) {
                let items = this.getType(type);
                if(value && this.locations && items) {
                    for(let item of items) {
                        if(item.id == value) {
                            return item.name;
                        }
                    }
                }
            },
            getType(type) {
                if(type == 'country') {
                    return this.locations.countries;
                } else if(type == 'state') {
                    return this.locations.states;
                } else {
                    return this.locations.lgas;
                }
            },
        },
        mounted() {
            this.getLocations(); // load locations like countries, states & lgas
        }
    };
</script>
<style scoped>
    .marginTop40 {
        margin-top: 40px;
    }
</style>
